body,html{height:100%}body{margin:0!important;font-family:Poppins,sans-serif!important}.see-all{font-weight:700}mat-card-title{font-size:1.6rem!important;line-height:1.8rem!important}mat-card{width:100%;box-shadow:none!important;height:100%!important;margin-bottom:1.6rem!important;border-radius:15px!important}.hr-blue{border-bottom:4px solid #1a237e;width:10%;text-align:left;margin-left:.1rem;z-index:1}.hr-red{border-bottom:4px solid #d50000;width:25%}h1,mat-card-title,mat-toolbar{font-family:Oswald,sans-serif!important;letter-spacing:.1rem!important}mat-toolbar{background-color:transparent!important;font-weight:600!important}.mat-chip-list-wrapper{flex-wrap:nowrap!important;overflow-x:scroll!important;overflow-y:hidden!important}.fa-youtube{color:#c4302b}.card-deck{display:flex!important}.card-deck-scroll{display:flex!important;overflow-x:scroll!important}.card{border:none!important;background-color:#fff!important;padding:1rem!important;border-radius:15px!important}.spacer{flex:1 1 auto!important}.space{display:flex!important;width:1.4rem!important}.gap{display:flex!important;height:1.875rem!important}mat-chip,small{font-size:14px!important;font-weight:600!important}a{cursor:pointer!important;text-decoration:none!important}.material-icons-round,i,mat-icon{vertical-align:middle!important;cursor:pointer;user-select:none;-webkit-user-select:none}.fa-facebook-f{color:#3b5998!important}.fa-twitter{color:#1da1f2}.fa-instagram{color:transparent;background:radial-gradient(circle at 30% 107%,#fdf497 0,#fdf497 5%,#fd5949 45%,#d6249f 60%,#285aeb 90%);background:-webkit-radial-gradient(circle at 30% 107%,#fdf497 0,#fdf497 5%,#fd5949 45%,#d6249f 60%,#285aeb 90%);background-clip:text;-webkit-background-clip:text}

.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
    padding: 0 !important;
}


.mat-bottom-sheet-container{
    border-radius: 30px; 
    max-height: 80vh !important;
    min-height: 40vh !important;
    min-width: 95vw !important;
    max-width: 95vw !important;
    margin-bottom: 10px !important;
  }
  .clubs-section{
    display: grid;
    gap: 3em;
    grid-template-columns: repeat(4,1fr);
    
  }
  
  .clubs-section-logo{
    aspect-ratio: 1/1;
    border-radius: 50%;
    height: 15em;
  }
  
  .clubs-section{
    text-align: center !important;
  }
  .clubs-section> div> a{
    font-weight: bold;
    color: #000;
  }


  @media only screen and (max-width: 820px) {

    .clubs-section,.events-section, .hero-section{
      display: grid !important;
      gap: 3em !important;
      grid-template-columns: repeat(1,1fr) !important;
      
    }

  }

 /* font-weight: 900;
  font-size: 4rem !important;
  background: -webkit-linear-gradient(#1a237e, #D50000) !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;  */